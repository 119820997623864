/* Styles for the full-page success */
body {
  margin: 0px !important;
}

.full-page-success {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2bae4a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-content {
  text-align: center;
  color: #ffffff;
}

.success-content i {
  font-size: 48px;
  margin-bottom: 20px;
}

.success-content h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

/* Styles for the full-page error */
.full-page-error {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(203, 29, 29, 0.8); /* Red background for error */
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-content {
  text-align: center;
  color: #ffffff;
}

.error-content i {
  font-size: 48px;
  margin-bottom: 20px;
}

.error-content h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.liqa-container {
  position: relative;
}

.fullscreen-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
}

.loader-wrapper {
  text-align: center;
  color: white;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #ffffff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Style for the button (you can adjust as needed) */
button {
  /* Your button styles here */
}

/* Example Font Awesome inclusion */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css");
